import { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Layout from './components/Layout/Layout';
//import UserProfile from './components/Profile/UserProfile';
import AuthPage from './pages/AuthPage';
//import HomePage from './pages/HomePage';

import HomeApp from './pages/HomeAppPage';
import MyVilla from './pages/MyVillaPage';
import Progress from './pages/ProgressPage';
import Financial from './pages/FinancialPage';
import Legal from './pages/LegalPage';
import Profile from './pages/ProfileAppPage';
import Construction from './pages/ConstructionPage';
import ConstructionStage from './pages/ConstructionStagePage'

import AuthContext from './store/auth-context';

import Contact from './pages/ContactPage';
import Payments from './pages/PaymentsPage';

//pages info goes here
import Info1 from './pages/Info1Page'
import Info2 from './pages/Info2Page'
import Info3 from './pages/Info3Page'
import Info4 from './pages/Info4Page'
import Info5 from './pages/Info5Page'
import Info6 from './pages/Info6Page'
import Info7 from './pages/Info7Page'
import Info8 from './pages/Info8Page'
import Info9 from './pages/Info9Page'
import Info10 from './pages/Info10Page'


function App() {
  const authCtx = useContext(AuthContext);

  return (  
    <Layout>
      <Switch>
        <Route path='/' exact>
          {!authCtx.isLoggedIn && <AuthPage />}
          {authCtx.isLoggedIn &&  <HomeApp /> }
        </Route>
        <Route path='/auth'>
          {!authCtx.isLoggedIn && <AuthPage />}
          {authCtx.isLoggedIn && <Redirect to='/home' />}
        </Route>
        {authCtx.isLoggedIn && (
          <>
          
          <Route path='/home'>
            <HomeApp />
          </Route>
          <Route path='/construction'>
            <Construction />
          </Route>
          <Route path='/cstage'>
            <ConstructionStage />
          </Route>
          <Route path='/myvilla'>
            <MyVilla />
          </Route>
          <Route path='/progress'>
            <Progress />
          </Route>
          <Route path='/financial'>
            <Financial />
          </Route>
          <Route path='/payments'>
            <Payments />
          </Route>
          <Route path='/legal'>
            <Legal />
          </Route>
          <Route path='/contact'>
            <Contact />
          </Route>
          <Route path='/profile2'>
            <Profile />
          </Route>
          <Route path='/vacation-club'>
            <Info1 />
          </Route>
          <Route path='/amenities'>
            <Info2 />
          </Route>
          <Route path='/subdivision-fee'>
            <Info3 />
          </Route>
          <Route path='/auth-pets'>
            <Info4 />
          </Route>
          <Route path='/wire-transfer'>
            <Info5 />
          </Route>
          <Route path='/tv-internet'>
            <Info6 />
          </Route>
          <Route path='/features'>
            <Info7 />
          </Route>
          <Route path='/personal-info'>
            <Info8 />
          </Route>
          <Route path='/roof-maintenance'>
            <Info9 />
          </Route>
          <Route path='/shuttle-bus'>
            <Info10 />
          </Route>
          </>
        )}
 
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
