const Info6PageContent = () => {

    return(
      <div className="container">
        <h1>Cable and Internet</h1>
        <div className="row justify-content-center">

          <div className="col">   
          <p><img width="160" height="57" src="img/cable-internet_clip_image002.jpg" alt="" /></p>
            <p ><img  src="img/delancer1.PNG" alt="" /></p>
              <p ><img  src="img/delancer2.PNG" alt="" /></p>
              <p ><img  src="img/delancer3.PNG" alt="" /></p>
          </div>
        </div>
      </div>
    );
  };
  
export default Info6PageContent;