const Info4PageContent = () => {

    return(
      <div className="container">
    
        <h1>Authorization to have pets</h1>
        <p><img width="285" height="106" src="img/logo.png" alt="" /></p>
        <p><strong>AUTHORIZATION TO HAVE PETS IN  THE PROPERTY</strong></p>

            <div>
              
              <p>Subject:  Authorization to have a</p>
              <ul>
                <li>Dog</li>
                <li>Cat</li>
                <li>Bird</li>
              </ul>
              <p>in Villa <u>              </u></p>
             
              <p>Sosúa, Date<u>                  </u>
            </p></div>
            <div>
              
              <p>RCL Administraciones authorizes you to have this pet inside your property, and we want to  remind you that pet owners are required to adhere to the rules set out in the rules and regulations  of Residencial  Casa Linda. Only dogs, cats and birds are allowed.</p>

              <p><strong>RULES FOR PETS WITHIN CASA LINDA:</strong></p>
              <p>o All  pets must be walked on a leash  and are not permitted to roam the project on  their  own.  Pets that are off a leash  can be threatening  to others, particularly small  children, and can run off,  causing damage to the neighborhood.<br/>
                o For dogs,  the yard must have a complete  perimeter fence or  the animal must be tied at all times.<br />
                o All pet waste  must be picked up by the owner  and not left anywhere  on lawns or  public areas.  Failure  to dispose of pet waste creates  an unclean and unhealthy environment for  everyone, particularly small  children. It forces our  staff or your neighbors to clean up  after you.<br />
                o Excessive  barking that bothers neighbors and causes  complaints is strictly prohibited.<br />
                Barking or  howling  by dogs and screeching by birds disrupts everyone  within hearing, particularly those  who are elderly or sick  or who have small sleeping children.</p>
              <p>&nbsp;</p>
              <p><strong>Pet infractions  and complaints have a three-strike rule:</strong> <br />
                1.   Warning<br />
                2.   Fine of $100 and<br />
                3.   Removal of the pet</p>
              <p>&nbsp;</p>
              <p>Thank you  for  your  cooperation.</p>
              <p> Respectfully yours.</p>
              <p><br />
                R.C.L. Administraciones S.R.L.</p>
            </div>
            <p>&nbsp;</p>
            <p><strong>B</strong><strong>y signing below, I hereby have read, understood and accept the rules and regulations of RCL.</strong></p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>Home Owner Name:</p>
            <p>&nbsp;</p>
            <p>Villa #: <u>                                          </u></p>
            <p>&nbsp;</p>
            <p>Date:  <u>                                            </u></p>
            <p>Home Owner Signature:</p>
            <p><u>                                            </u></p>
      </div>
    );
  };
  
export default Info4PageContent;