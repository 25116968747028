import React, { useState, useEffect, useCallback } from 'react';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedExpirationDate = localStorage.getItem('expirationTime');
  const dataInfo = localStorage.getItem('owner');
  const villasInfo = localStorage.getItem('villas');

  const qtyVi = localStorage.getItem('qtyVilla');
  const selectedVilla = localStorage.getItem('selected');

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 60) {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('owner');
    localStorage.removeItem('villas');

    localStorage.removeItem('qtyVilla');
    localStorage.removeItem('selected');
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
    data: dataInfo,
    villasInfo: villasInfo,
    qtyVillas: qtyVi,
    selectedV: selectedVilla,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  
  let initialToken;
  let ownerData;
  let villasDetails;

  let qv;
  let villaSelect;

  if (tokenData) {
    initialToken = tokenData.token;
    ownerData = tokenData.data;
    villasDetails = tokenData.villasInfo;
    qv = tokenData.qtyVillas;
    villaSelect = tokenData.selectedV;
  }
  const [data, setData] = useState(ownerData);
  const [token, setToken] = useState(initialToken);
  const [villas, setVillas] = useState(villasDetails);

  const [villaSelected, setVilla] = useState(villaSelect);
  const [totalVillas, setQty] = useState(qv);



  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('owner');
    localStorage.removeItem('villas');

    localStorage.removeItem('qtyVilla');
    localStorage.removeItem('selected');

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token, expirationTime, data, villas,villaSelected,qty) => {
    setToken(token);
    setData(data);
    setVillas(villas);

    setVilla(villaSelected);
    setQty(qty);

    localStorage.setItem('token', token);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('owner', data);
    localStorage.setItem('villas', villas);

    localStorage.setItem('selected', villaSelected);
    localStorage.setItem('qtyVilla', qty);

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      //console.log(tokenData.duration);
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    dataInf: data,
    villasDetails:villas,
    isLoggedIn: userIsLoggedIn,
    villaSelected: villaSelected,
    qtyVillas: totalVillas,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
