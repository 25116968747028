const Info10PageContent = () => {

    return(
      <div className="container">
     
        <h1>Shuttle Bus Schedule</h1>
       
        <img className="img-fluid" src="img/map CL shuttle 2021.jpg" alt=""/>
      </div>
    );
  };
  
export default Info10PageContent;