import { useContext, useState, useRef } from 'react';
import AuthContext from '../../store/auth-context';
import { Link } from 'react-router-dom';
import './style.css';

import axios from 'axios';

const ProfileApp = () => {
    const[showForm, setShowForm]=useState(false)
    const[isLoading, setIsLoading]=useState(false)
    const authCtx = useContext(AuthContext);
    const [passwordShown1, setPasswordShown1] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [passwordShown3, setPasswordShown3] = useState(false);
    
    const oldPassRef = useRef();
    const newPassRef = useRef();
    const confirmPassRef= useRef();

    const owner = authCtx.dataInf;
    let propietario=JSON.parse(owner);
    const totalVillas = authCtx.qtyVillas;

    const vi = authCtx.villasDetails;
    let villas;
    vi===undefined ? villas='' : villas = JSON.parse(vi)

    const togglePassword1 = () => {
        setPasswordShown1(!passwordShown1);
    };
    const togglePassword2 = () => {
        setPasswordShown2(!passwordShown2);
    };
    const togglePassword3 = () => {
        setPasswordShown3(!passwordShown3);
    };

    const submitHandler = (event) => {
        event.preventDefault(); /*do not allow to refresh page after submitted form*/
        //update password here
        setIsLoading(true)
        const enteredOldPass = oldPassRef.current.value;
        const enteredNewPass = newPassRef.current.value;
        const enteredConfirmedPass = confirmPassRef.current.value;
        if(enteredNewPass!==enteredConfirmedPass){
            alert('Error: New password mismatch with confirmed new password\nPlease, try again.')
        }else{
            //send request to API
           // alert('form submitted')
           const APIUrl='https://api.casalindacity.com/owner/'+propietario['id'];
          // alert(APIUrl);

            const apiBody = { oldpass: enteredOldPass, newpass : enteredNewPass };

            const apiHeader=  {
                headers: { 'Content-Type': 'application/json', 'Token': '3d524a53c110e4c22463b10ed32cef9d'  }
            };
          
                const axiosConnect = axios.put
                axiosConnect(APIUrl, apiBody, apiHeader).then(function(axiosResult) {

                    const result=axiosResult.data;
                    const estado = axiosResult.status;
                    console.log('resultado:', axiosResult)
                    console.log('resultado datos:', axiosResult.data)
                    console.log('resultado status:', axiosResult.status)
                    alert(result.message);
                    setShowForm(false)
                }).catch(err => {
                    // Handle error
                    console.log('error atrapado:', err);
                    console.log('msg:', err.response.data.message);
                    console.log('code:', err.response.status);
                    alert(err.response.data.message)
                });
            
        }
       
        setIsLoading(false)
    }

    return (
        <div className="container">
        <h2 style={{color:'purple', marginTop:'50px'}}>My Villa - Profile</h2>
        <div className="progress">
    
        </div>
        <h1 style={{color:'grey', marginTop:'50px'}}>Owner's Details</h1>

        <div className="row">
            {/*<div className="col-3 d-none d-sm-block">
                <div className="row border text-center rounded-3" style={{margin:'20px', padding:'20px', backgroundColor:'#c5ebe8'}}>
                    <div className="col" style={{marginTop:'90px', marginBottom:'90px'}}>
                        <i className="fa-solid fa-circle-user fa-7x" style={{color:'purple'}}></i>
                        <h3>{propietario['full_name']}</h3>
                        <p>{propietario['email']}</p>
                    </div>
                </div>
            </div>*/}
            <div className="col">
                
                <h2 style={{color:'purple'}}>Information</h2>
                <hr/>
                <div className="row" style={{paddingBottom:'35px'}}>
                    <div className="col">
                        <h3 style={{color:'#959da3'}}>Full Name</h3>
                        <h4>{propietario['full_name']}</h4>
                        <Link to="/profile2" className='updatepwd' onClick={()=>setShowForm(!showForm)}>Update your Password</Link>
                    {(showForm) && (!isLoading) ? (
                        <form onSubmit={submitHandler}>
                            <div >
                                <label htmlFor='oldpass' className='etiqueta'>Old password</label>
                                <input className='form-control' type={passwordShown1 ? "text" : "password"} id='oldpass' required ref={oldPassRef} />
                                <span className='showHide' onClick={togglePassword1}>{passwordShown1 ? "Hide Password" : "Show Password"}</span>
                            </div>
                            <div >
                            <label htmlFor='newpassword' className='etiqueta'>New Password</label>
                            <input className='form-control'
                                type={passwordShown2 ? "text" : "password"}
                                id='newpassword'
                                required 
                                ref={newPassRef}
                                
                            /><span className='showHide' onClick={togglePassword2}>{passwordShown2 ? "Hide Password" : "Show Password"}</span>
                            </div>
                            <div >
                            <label htmlFor='cnewpassword' className='etiqueta'>Confirm New Password</label>
                            <input className='form-control'
                                type={passwordShown3 ? "text" : "password"}
                                id='cnewpassword'
                                required 
                                ref={confirmPassRef}
                                
                            /><span className='showHide' onClick={togglePassword3}>{passwordShown3 ? "Hide Password" : "Show Password"}</span>
                            </div>
                            <div className=''>
                            {isLoading ? (<p> Loading...</p>):(
                                <button type='submit' className='boton'>Change password</button>
                            )}
                            </div>
                        </form>
                    ) : (<p> &nbsp;</p>)}

                    {isLoading &&(
                        <p>Please wait, loading...</p>
                    )}
                    </div>
                    <div className="col">
                        <h3 style={{color:'#959da3'}}>Villa(s)</h3>
                        <h4>{totalVillas} total</h4>
                        <ul  >
                            {villas?.map(villa => <li key={villa.id}> {villa.no} </li>)}
                        </ul>
                    </div>
                </div>
                
                <hr/>
                <div className="row">
                    <div className="col">
                        <h3 style={{color:'#959da3'}}>Email</h3>
                        <h4>{propietario['email']}</h4>
                    </div>
                    <div className="col">
                        <h3 style={{color:'#959da3'}}>Phone</h3>
                        <h4>{propietario['phone']}</h4>
                    </div>
                </div>
            </div>
            
        </div>


        </div>
    );
};

export default ProfileApp;