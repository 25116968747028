import {useState, useEffect, useContext} from 'react'
import AuthContext from '../../store/auth-context';




const ProgressPageContent = () => {
  //let today = new Date();
  //today.setHours(0,0,0,0);

  const authCtx = useContext(AuthContext);
  const vi = authCtx.villaSelected;
  const villa = JSON.parse(vi);

  let probar=5 //initial value

  const [bar, setBar]=useState(5)
  const [item1, setItem1]=useState(1)
  const [item2, setItem2]=useState(0)
  const [item3, setItem3]=useState(0)
  const [item4, setItem4]=useState(0)
  const [item5, setItem5]=useState(0)
  const [item6, setItem6]=useState(0)
  const [item7, setItem7]=useState(0)
  const [item8, setItem8]=useState(0)
  const [item9, setItem9]=useState(0)
  const [item10, setItem10]=useState(0)
  const [item11, setItem11]=useState(0)
  const [item12, setItem12]=useState(0)
  const [item13, setItem13]=useState(0)
  const [item14, setItem14]=useState(0)
  const [item15, setItem15]=useState(0)
  const [item16, setItem16]=useState(0)
  const [item17, setItem17]=useState(0)
  const [item18, setItem18]=useState(0)
  const [item19, setItem19]=useState(0)
  const [item20, setItem20]=useState(0)

  const handleOnChangeItem1 = ()=>{
    
    item1===1 ? (<> {setBar((prev)=>prev-5)} {setItem1(!item1)} </> ): (<> {setBar((prev)=>prev+5)} {setItem1(!item1)} </>)
  }
  const handleOnChangeItem2 = ()=>{
    setItem2(!item2)
    item2===1 ? setBar((prev)=>prev-5) : setBar((prev)=>prev+5)
  }
  const handleOnChangeItem3 = ()=>{
    setItem3(!item3)
    item3===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem4 = ()=>{
    setItem4(!item4)
    item4===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem5 = ()=>{
    setItem5(!item5)
    item5===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem6 = ()=>{
    setItem6(!item6)
    item6===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem7 = ()=>{
    setItem7(!item7)
    item7===1 ? setBar(bar-5) : setBar(bar+5) 
  }
  const handleOnChangeItem8 = ()=>{
    setItem8(!item8)
    item8===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem9 = ()=>{
    setItem9(!item9)
    item9===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem10 = ()=>{
    setItem10(!item10)
    item10===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem11 = ()=>{
    setItem11(!item11)
    item11===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem12 = ()=>{
    setItem12(!item12)
    item12===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem13 = ()=>{
    setItem13(!item13)
    item13===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem14 = ()=>{
    setItem14(!item14)
    item14===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem15 = ()=>{
    setItem15(!item15)
    item15===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem16 = ()=>{
    setItem16(!item16)
    item16===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem17 = ()=>{
    setItem17(!item17)
    item17===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem18 = ()=>{
    setItem18(!item18)
    item18===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem19 = ()=>{
    setItem19(!item19)
    item19===1 ? setBar(bar-5) : setBar(bar+5)
  }
  const handleOnChangeItem20 = ()=>{
    setItem20(!item20)
    item20===1 ? setBar(bar-5) : setBar(bar+5)
  }

  const SeeProgress = ()=>{
    let reserv=parseInt(villa.rreceived)
    let scrow=parseInt(villa.escrow)
    let reservation=reserv+scrow
    
    if(probar===0){
      setItem1(0)
    }
     //check reservation form
     if( villa.rforms==="1"){
      setItem2(1)
      probar=probar+5;
      //alert('reservation yes')
    }
    //check POA
    if( villa.poa!==''){
      setItem3(1)
      probar=probar+0;
      //alert('poa')
    }
    //check reservation payment
    if( reservation>0){
      setItem4(1)
      probar=probar+10;
      //alert(probar)
    }


    //check in lot
    if( villa.planid!==''){
      setItem5(1)
      probar=probar+5;
      //alert(probar)
    }
    //check quotation
    if( villa.qtnid!==''){
      setItem6(1)
      probar=probar+5;
      //alert(probar)
    }
    //check Material selection
    if( villa.pcoordinator==="1"){
      setItem7(1)
      probar=probar+5;
      //alert(probar)
    }
    //check Technical and structural plans
    if( villa.planreceived==="1"){
      setItem8(1)
      probar=probar+5;
      //alert(probar)
    }
    //check promise of sale
    if( villa.contract==="1"){
      setItem9(1)
      probar=probar+5;
      //alert(probar)
    }
    //check if 35% minus reservation is paid
    let saleP=parseInt(villa.salesprice)
    let fpay=saleP*0.34
    let payment1=parseInt(villa.cpayment)
    let payment2=parseInt(villa.cpayment2)
    let totalpayments=reservation+payment1+payment2
    //alert(fpay)
    if( totalpayments>=fpay){
      setItem10(1)
      probar=probar+5;
      //alert(probar)
    }
    //check started construction
    let startedDate =new Date(villa.stardate)
    let today=new Date()
    //alert(startedDate)
    //alert(today)
    if( startedDate<=today){
      setItem11(1)
      probar=probar+5;
      //alert(probar)
    }
    //check Second construction payment
    if( payment2>0){
      setItem12(1)
      probar=probar+5;
      //alert(probar)
    }
    //check third construction payment
    if( villa.cpayment3!=="0.00"){
      setItem13(1)
      probar=probar+5;
      //alert(probar)
    }
    //check fourth construction payment
    if( villa.cpayment4!=="0.00"){
      setItem14(1)
      probar=probar+5;
      //alert(probar)
    }
    //delivery notice by Janine
    if( villa.deliveredcl!=="0000-00-00"){
      setItem15(1)
      probar=probar+5;
      //alert(probar)
    }
    //Final contract
    if( villa.fcfile!==""){
      setItem16(1)
      setItem17(1)
      probar=probar+10;
      //alert(probar)
    }
  
    //check if delivered
    if( villa.deliveredclient!=="0000-00-00"){
      setItem18(1)
      probar=probar+5;
      //alert(probar)
    }
    //check if deficiency report
    if( villa.defclient!==""){
      setItem19(1)
      probar=probar+5;
      //alert(probar)
    }
    //check if deficiency resolved
    if( villa.defcomplete!=="0000-00-00"){
      setItem20(1)
      probar=probar+5;
      //alert(probar)
    }
    
   
    //alert(reservation)
    setBar(probar)
  }

  useEffect(()=>{
    SeeProgress()
    console.log(villa)
    
  }, [])

  const ProgressPercent = () => {
    //e.preventDefault(e);
    
    setBar(80)
  //return 25;
  }

  const onCheckboxClick=(e)=> {
    if (this.isCheckboxDisabled) {
       e.preventDefault();
       return false;
    }
 }
 // const startDate = mew Date('2022-01-15');
  //const endDate= mew Date('2022-09-20');

    return (
  
      <div className="container">
        {/*item3 && ( <button onClick ={ProgressPercent}>Check progress </button>)*/}
       
        <h2  style={{color:'purple', marginTop:'50px'}}>Overall Progress to Villa Delivery</h2>
        <div className="progress">
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: bar+'%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{bar}%</div>
        </div>

      <h2 style={{color:'grey', marginTop:'3%'}}>Check list</h2>
     <div className="row">
        <div className="col-md">

      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={item1} onChange={handleOnChangeItem1} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckDefault" > 1- Selected villa model or custom </label></h4>
      </div>
      
  
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item2}  onChange={handleOnChangeItem2} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked">2- Reservation form</label></h4>
      </div>
      
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item3} onChange={handleOnChangeItem3} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked" style={{color:'black'}}>3- Power of attorney (if required)</label></h4>
      </div>

      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item4} onChange={handleOnChangeItem4} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked">4- Deposit of Reservation</label></h4>
      </div>
      
   
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item5} onChange={handleOnChangeItem5} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked">5- In lot and 3D Plans</label></h4>
      </div>
  
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item6} onChange={handleOnChangeItem6} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked">6- Quotation from sales</label></h4>
      </div>
  
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item7} onChange={handleOnChangeItem7} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked">7- Material Selection</label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item8} onChange={handleOnChangeItem8} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked">8- Technical and structural plans</label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked"  checked={item9} onChange={handleOnChangeItem9} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked">9- Promise of sale </label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item10} onChange={handleOnChangeItem10} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked">10- 35% minus reservation must be paid</label></h4>
      </div>
      </div>
      <div className="col-md">

      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item11} onChange={handleOnChangeItem11} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked">11- Started Construction </label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item12}  onChange={handleOnChangeItem12} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked" style={{color:'black'}}>12- Second construction payment </label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item13} onChange={handleOnChangeItem13} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked" style={{color:'black'}}>13- Third construction payment </label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item14}  onChange={handleOnChangeItem14} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked" style={{color:'black'}}>14- Fourth construction payment </label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked"  checked={item15} onChange={handleOnChangeItem15} onclick="onCheckboxClick" disabled/>
        <label className="form-check-label" htmlFor="flexCheckChecked" style={{color:'black'}}>15- Delivery notice given by Janine </label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item16} onChange={handleOnChangeItem16} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked" style={{color:'black'}}>16- Final contract prepared </label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item17} onChange={handleOnChangeItem17} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked" style={{color:'black'}}>17- Final contract signed </label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item18}  onChange={handleOnChangeItem18} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked" style={{color:'black'}}>18- Delivery </label></h4>
      </div>
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item19} onChange={handleOnChangeItem19} onclick="onCheckboxClick" disabled/>
        <label className="form-check-label" htmlFor="flexCheckChecked" style={{color:'black'}}>19- Punch list report </label></h4>
      </div>
    
      <div className="form-check">
        <h4><input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={item20} onChange={handleOnChangeItem20} onclick="onCheckboxClick" disabled />
        <label className="form-check-label" htmlFor="flexCheckChecked" style={{color:'black'}}>20- Punch list resolved</label></h4>
      </div>

      </div>
      </div>
    </div>
     
    );
};

export default ProgressPageContent;