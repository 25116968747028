const Info2PageContent = () => {

    return(
      <div className="container">
     
        <h1>Amenities</h1>
            <h3><img className="img-fluid" src="img/amenities2.jpg" width="1774" height="685" alt=""/>&nbsp;</h3>
            <h3>Casa Linda is a full service development which caters to foreign buyers. 
              Our services include: </h3>
            <div>
              <div>
                <h2>Convenient, Quiet Location</h2>
                <ul>
                  <li>Casa Linda is located minutes to several beautiful local beaches, shopping, activities, and other restaurants</li>
                  <li>We are only 20-minutes to Puerto Plata International Airport</li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <h2> Our Home Owners Matter</h2>
                <ul>
                  <li>Foreign buyers require special services and access to information.  We offer services that other projects don&rsquo;t:</li>
                  <li>Owner&rsquo;s Portal for instant online information</li>
                  <li>Home Owner&rsquo;s Group</li>
                  <li>Dedicated Owner&rsquo;s Liaison staff member</li>
                  <li>Facebook Owner&rsquo;s Page</li>
                  <li>Community Events, games, dances, discounts, beach clubs and more.</li>
                </ul>
              </div>
            </div>
            <h2>Longevity &amp; Outstanding Service for Owners</h2>
            <ul>
              <li>27+ year history of construction, administration and rental management.</li>
              <li>340+ villas constructed to date.</li>
              <li>24-hour gated security. Lock up your villa and know it is in good hands</li>
              <li>Onsite, comprehensive administration department with English speaking office. Services include bill payments and emergency contact.</li>
              <li>Internationally marketed rental program.</li>
              <li>1-6/day per week garden, pool and housekeeping service</li>
            </ul>
            <h2>Onsite Amenities For Everyone</h2>
            <ul>
              <li><strong>Complimentary shuttle bus</strong> service to Sosua and Cabarete several times per day, 7-days/week</li>
              <li>Castaway&rsquo;s Clubhouse &amp; Eatery – excellent food but also a meeting and event place for owners.  Happy Hour daily specials and lots of fun.</li>
              <li>On-site tennis, bocce ball and shuffleboard courts,  mini-golf.</li>
              <li>Waterpark and lazy river</li>
              <li>Beautiful communal grounds &amp; tropical perimeter walking trail</li>
              <li>High speed fibre optic Internet and cable</li>
              <li>24-hour electricity with back up system</li>
              <li>Many floor plans &amp; custom builds</li>
            </ul>
      </div>
    );
  };
  
export default Info2PageContent;