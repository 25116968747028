import { useContext } from 'react';

import AuthContext from '../../store/auth-context';

const Footer = () => {
  const authCtx = useContext(AuthContext);

  return (
      <>
       { authCtx.isLoggedIn && 
        <footer className="footer mt-auto py-3 bg-light2" >
            <div className="container" >
                <span className="bg-light2">Casa Linda - Quality Communities @ My Page.</span>
            </div>
        </footer>
      }
    </>
  );
};

export default Footer;
