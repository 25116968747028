const Info9PageContent = () => {

    return(
      <div className="container">

        <h1>Roof Maintenance</h1>
        <div className="row justify-content-center">

          <div className="col">   
              <p><img width="265" height="102" src="img/logo.png" alt="" /></p>
              <p>&nbsp;</p>
              <p align="center"><strong><u>R</u></strong><strong><u>OO</u></strong><strong><u>F MAINTENANCE</u></strong></p>
              <p>&nbsp;</p>
              <p><strong>TILED ROOF </strong>– Routine inspection to check for broken or cracked tiles that may be caused by weather or fallen tree branches.</p>
              <p>&nbsp;</p>
              <p><strong>FLAT ROOF </strong>– Roofs with asphalt fabric (tela asfaltica) that has a granular surface. Routine inspection is necessary to make sure seams or joints between the pieces are  sealed and have  no cracks that may be  caused by exposure to the  sun.</p>
              <p>&nbsp;</p>
              <p><strong>FLAT ROOF </strong>- Roofs with asphalt fabric (tela asfaltica) that does not have a granular surface must be painted every year with an  aluminum paint. (for example, Edil aluminum coating and follow  manufacturer&rsquo;s instructions) Routine  inspection is necessary to make sure seams or joints between the pieces are sealed and  have no cracks that may be caused by exposure to the  sun.</p>
              <p>&nbsp;</p>
              <p><strong>FLAT ROOFS </strong>– Roofs that do not have asphalt fabric, must be resealed every second year. (Lanco  brand, or any other good quality sealer)</p>
              <p>&nbsp;</p>
              <p>IF  YOU WOULD LIKE A  QUOTE, PLEASE  DO NOT  HESITATE TO CONTACT OUR  MAINTENANCE  DEPARTMENT.</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p><strong>Clients name: <u>                                                 </u> Villa: <u>                        </u></strong></p>
              <p><strong>Signature: <u>                                         </u></strong></p>
              <p><strong>D</strong><strong>ate: <u>                              </u></strong></p>
          </div>
        </div>
      </div>
    );
  };
  
export default Info9PageContent;