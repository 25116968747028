import React, {useContext, useState } from 'react';
import PdfModal from '../PdfModal/PdfModal';
import {Link} from "react-router-dom";
import AuthContext from '../../store/auth-context';

const baseServer='https://lots.casalindacity.com/';


const ConstructionPageContent = () => {
  const authCtx = useContext(AuthContext);
  const villaSelected=authCtx.villaSelected;
  let sVilla;
  villaSelected===undefined ? sVilla='' : sVilla=JSON.parse(villaSelected)
  let urlPdf=baseServer+sVilla['fpabc'];

  const enteredUsername='';
  const [error, setError] = useState();
  
  const addPdfHandler = (event) => {
    event.preventDefault();
    if (enteredUsername.trim().length === 0 ) {
      setError({
        title: 'Technical Plans for villa # '+sVilla['no'],
        message: 'Technical Plans villa # '+sVilla['no'],
        pdfFile: urlPdf,
      });
      return;
    }
    
  };

  const errorHandler = () => {
      setError(null);
    };

    return (
        <div className="container">
           {error && (
            <PdfModal
              title={error.title}
              message={error.message}
              pdf={error.pdfFile}
              onConfirm={errorHandler}
            />
          )}

          <h2 style={{color:'purple', marginTop:'3%'}}>My Page - Construction</h2>
          <div className="progress">
          {/*<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: '38%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">38%</div>*/}
        </div>
    <h1 style={{color:'black', marginTop:'3%'}}>Construction Overview</h1>

   <div className="row">
      <div className="col" style={{margin:'3%', textAlign:'center'}}>
		<Link to="/construction"  onClick={addPdfHandler}>
			<i className="fa-duotone fa-cube fa-4x"></i>
			<h3>Technical Plans</h3>
		</Link>	
    </div>
    <div className="col" style={{margin:'3%', textAlign:'center'}}>
      <Link to="/cstage">
        <i className="fa-duotone fa-hammer fa-4x"></i>
        <h3>Construction Stage</h3>
      </Link>		
    </div>
   {/*
	<div className="col" style={{margin:'3%', textAlign:'center'}}>
  <Link to="/construction">
			<i className="fa-duotone fa-ruler-triangle fa-4x"></i>
			<h3>Technical Plans</h3>
   </Link>	
    </div>*/}
  </div>
     <div className="row">
      
    
	<div className="col" style={{margin:'3%'}}>
		
    </div>
  </div>


</div>
    );
};

export default ConstructionPageContent;