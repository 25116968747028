import React from 'react';

import Card from './Card';
import Button from './Button';
import classes from './PdfModal.module.css';

const PdfModal = (props) => {
  return (
    <div>
      <div className={classes.backdrop} onClick={props.onConfirm} />
      <Card className={classes.modal}>
        <header className={classes.header}>
          <h4>{props.title}</h4>
          <button type="button" style={{ float:'right', marginTop:'-25px',}} className="btn-close btn-close-white" ariaLabel="Close" onClick={props.onConfirm}></button>
        </header>
        <div className={classes.content}>
          {/*<p>{props.message}</p>*/}
          
          <object width="100%" height="700" data={props.pdf} type="application/pdf"> </object>
        </div>
        <footer className={classes.actions}>
          <Button onClick={props.onConfirm}>Okay</Button>
        </footer>
      </Card>
    </div>
  );
};

export default PdfModal;
