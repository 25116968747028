const Info8PageContent = () => {

    return(
      <div className="container">
      
        <h1>Personal Information Request</h1>
        <div className="row justify-content-center">

          <div className="col">  


          <p><img width="265" height="101" src="img/personal-info_clip_image002.jpg" alt="" /></p>
            <p align="center">Carretera Sosúa- Cabarete, entrada El Choco -Sosúa, Puerto Plata República Dominicana<br />
              809-571-1190<a href="mailto:reception@casalindacity.com"> reception@casalindacity.com</a> - <a href="http://www.casalindacity.com/">www.casalindacity.com</a></p>
            <p>&nbsp;</p>
            <p align="center"><strong>OWNERS PERSONAL INFORMATION REQUIREMENT</strong></p>
            <p>&nbsp;</p>
            <p>1.  Legal  First and Last Name:</p>
            <p>&nbsp;</p>
            <p>2.  Nationality:</p>
            <p>&nbsp;</p>
            <p>3.  Self Employed? Or with whom are you employed with?</p>
            <p>&nbsp;</p>
            <p>4.  Passport number and Copy:</p>
            <p>&nbsp;</p>
            <p>5.  Mailing address:</p>
            <p>&nbsp;</p>
            <p>6.  E-mail address:</p>
            <p>&nbsp;</p>
            <p>7.  Marriage status:</p>
            <p>&nbsp;</p>
            <p>8.  Cel./Telephone number:</p>
            <p>&nbsp;</p>
            <p>9.  In case of an emergency, who should we contact?:</p>
            <p>&nbsp;</p>
            <p>10. Villa # in Casa Linda: </p>
            <p>&nbsp;</p>
            <p>Signature<u>              </u></p>
          </div>


        </div>
      </div>
    );
  };
  
export default Info8PageContent;