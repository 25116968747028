const Info7PageContent = () => {

    return(
      <div className="container">
      
        <h1>General features</h1>
        <div className="row justify-content-center">

          <div className="col">  
                  <p><img width="285" height="106" src="img/logo.png" alt="" /></p>
                  <p align="center"><strong>GENERAL FEATURES</strong></p>
                  <p>&nbsp;</p>
                  <p>●    All villas are fitted with a &lsquo;hot water&rsquo; tank which is fueled by propane. Two filled propane tanks will be delivered to your villa prior to the end  of construction so we can ensure the hot water system operates properly. The cost the propane for the first fill will be shown on your monthly statement.</p>
                  <p>Alternatively, you can have a &lsquo;Start-up Package&rsquo; added to your purchase price. The<br/>
                    &lsquo;Start-up Package&rsquo; includes the 2 propane tanks (only), with connections &amp; regulator and all gardening and pool equipment  that  will  be required to care for  your  property.  The  cost to fill these tanks will appear  on  your monthly statement.</p>
                  <p>●    There will be a ceiling fan with 1 light in all bedrooms, living room, dining room, and the covered terrace, (the number of fans for the terrace is determined  by size of the terrace).</p>
                  <p>●    Light fixtures that are included in the construction are the pool lights, the lights built into the wood valence of the bathroom  mirrors and the recessed lights above the outdoor bar (if in the plan). The purchase and installation of  all other light fixtures are the responsibility of the home owner. the lights fixtures we installed in general in the villa it is a standard incandescent light bulb and a socket, these bulbs do not have much useful  life and are high consumption, we recommend to change the bulbs to fluorescent or LED bulbs in order to save energy.</p>
                  <p>●    There will be one 9,000BTU air conditioner unit per bedroom with remote control,  if  the bedroom it is bigger than the standard it will be a charge for the difference.</p>
                  <p>●   All windows will have fixed screens and sliding doors will have sliding screens.</p>
                  <p>●   The glass in the bathroom windows will  be  hammered glass for privacy.</p>
                  <p>●    Doors will have door stops. Bedroom doors will have a key lock and bathroom doors will have a privacy lock, (unless specified).</p>
                  <p>●    All exterior doors will have a door sweep; (bottom weather strip).Poli Metal white doors  are standard in exterior palapa doors (including service, laundry, pool bathrooms and storage rooms) as exposure to rain and sun will damage wood doors.  These doors are  only available in the height of 210cm.</p>
                  <p>●   The standard paint for the interior and the exterior is acrylic matte finish from the<br/>
                    Pintura Popular revetex or plain finish.</p>
                  <p>●    All villas come with a fully landscaped garden. Size and quantity of plants and trees  vary depending on the villa and lot size.</p>
                  <p>●    Water supplied from the cistern to the house is pumped via a submersible pump  located in the cistern, (except for villas in phase 9).</p>
                  
                  <div>
                    <p>●    Pressure  (or  holding   tanks)   located   in  the  technical  room   are   made   of fiberglass.</p>
                    <p>●    Houses in the rental program will have an electronic key card entry system  installed on the front door  at no extra cost to the homeowner. Privately owned homes come with a standard lockset with keys.</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>KITCHEN</strong></p>
                    <p>●    The bases cabinets (including the tile zocalo and granite counter-top) are 92cm high. Upper cabinets are 76cm high and 30cm  deep with one shelf.</p>
                    <p>●   There is a minimum of one bank of drawers in the kitchen.</p>
                    <p>●    The extractor fan or combination microwave/extractor fan above the stove in the kitchen  will be vented outdoors, (appliance not provided).</p>
                    <p>●    Wiring and a switch for under-cabinet lighting are included in new construction. (light fixtures not provided).</p>
                    <p>●    Please note we have built the kitchen and laundry according to the dimensions you  provide us for the appliances. Appliance installation is not included in the construction of your villa but we offer that service with a cost.</p>
                    <p>●    The model of the villa will determine the number of lights outlets above the island or  peninsula.</p>
                    <p><strong>POOL</strong><strong>S</strong></p>
                    <p>●   Standard color for pools is off-white with mosaic tiles around the top.</p>
                    <p>●   The steps leading into a pool will have mosaic tiles inserted into the steps.</p>
                    <p>●    Pool decks will be finished in 30cm x 30cm coralline tile with coralline bullnose edge around the pool.</p>
                    <p>●   Every villa will have one pool shower with cold water only.</p>
                    <p>●    The cost to purchase pool maintenance equipment is the responsibility of the homeowner. (hose, pole, skimmer screen, vacuum head etc.,).</p>
                    <p>●    All pools are set up as chlorine system, but can be converted to a salt water system one month or  longer  after the pool  has been filled for the first  time. The salt water  chlorinator is an upgrade, and cost for the bags of salt are the homeowners responsibility.</p>
                    <p>●    The chlorine added to a new pool by the builder is only sufficient to maintain  water quality for a short period of time.</p>
                    <p>Please contact our Liaison Officer (Karina Bravo) to set up regular pool and garden services as soon as you are notified that construction is complete.</p>
                  </div>
                  
                  <div>
                    <p><strong>BATHROOMS</strong></p>
                    <p>●   Faucets are single lever style in chrome. 1 year warranties</p>
                    <p>●   Wood frame of the mirrors are 8  cm wide.</p>
                    <p>●   All toilets, sinks (bidets &amp; bathtubs if in the plan) will be white.</p>
                    <p>●    If a bathtub is in the plan, it will be a drop-in acrylic tub (aprox, 60&rdquo; x 32&rdquo;) Bathtubs do not come with tub/shower panels, sliding doors or shower curtain.</p>
                    <p>●  The entrance  to the shower area  will   have a small step-over wall. (unless specified).Standard shower faucets will  have an exposed valve, with a hand  held shower head with a hose and an adjustable wall bracket.</p>
                    <p>●    Bathroom  walls are covered in ceramic tiles up to the height or just above the doors and windows. (approx. 220cm.)</p>
                    <p>●    Half bathrooms such as pool bathrooms will have tile up to approx. 1 meter above the floor.</p>
                    <p>●    Chrome bathroom accessories i.e.; paper dispenser, towel bar, etc. are  included.  (these are only sold in 5 pc. Set)</p>
                    <p>&nbsp;</p>
                    <p>All new residential  construction goes through a period of stabilization and settle. As a result, the home will experience minor material changes which are unavoidable and considered normal, in specifically cracks on the ceiling and walls plaster, therefore after this period we will proceed to fix the cracks all  at the same time, this period will  be 1 year after the villa finish date. The owner should also be aware that he/she is responsible for  proper home maintenance. Damage caused  by owner&rsquo;s negligence, improper maintenance or changes, alterations, or additions performed by  anyone other than Casa Linda, its employees,  or subcontractors is excluded from  the warranty.</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>WARRANTIES –</strong></p>
                    <p>Understandably, natural wear and tear isn&rsquo;t covered by a  new home warranty, neither is  weather damage or any problems resulting from owners not maintaining the property  adequately.</p>
                    <p>&nbsp;</p>
                    <p><img width="22" height="16" src="img/gral-features_clip_image004.gif" alt="" /> The structure of the villa will have a 5-year warranty</p>
                    <p><img width="22" height="16" src="img/gral-features_clip_image004_0000.gif" alt="" /> Mechanical or  electrical items such as pumps, fans, water heaters etc. are only  covered by the manufacturer&rsquo;s warranty.</p>
                    <p><img width="22" height="16" src="img/gral-features_clip_image004_0001.gif" alt="" /> With proper maintenance, the pool and roof of your villa will have a 5-year warranty  offered  by   Casa  Linda.  See   maintenance  instructions   attached,  the   maintenance should be given every 2 years.</p>
                    <p><img width="22" height="16" src="img/gral-features_clip_image004_0002.gif" alt="" /> Interior floors made with coralline and pool deck must be maintained every other year as a natural stone will present little holes and cracks with wear and tear through time</p>
                  </div>
                  
                  <p>and walk on it, also the soil in this part of the city it is red, so with time coralline will turn red.</p>
                  <p><img width="22" height="16" src="img/gral-features_clip_image004_0003.gif" alt="" /> Air-conditioners have a 1-year warranty. Must be clean and maintained properly. <img width="22" height="16" src="gral-features_clip_image004_0004.gif" alt="" />Aluminum sliding doors and windows have a manufacturer&rsquo;s warranty.<br/>
                    <img width="22" height="16" src="img/gral-features_clip_image004_0005.gif" alt="" /> Wood furniture and doors should be maintenance by the clients, as a tropical weather  we  have cause of the humidity and heat  the wood could shrink and the caulk can be retracted, so from  time to time caulk may need to be renewed.</p>
                  <p><img width="22" height="16" src="img/gral-features_clip_image004_0006.gif" alt="" /> Landscaping is subject to care and maintenance and weather conditions.</p>
                  <p><img width="22" height="16" src="img/gral-features_clip_image004_0007.gif" alt="" /> Bathroom, kitchen and bar faucets are made in chrome, 1 year warranties won&rsquo;t  include if the faucet have been clean up  with harsh detergents or any acid, hits or  wrong manipulation.</p>
                  <p>&nbsp;</p>
                  <p><strong>RC</strong><strong>L offers maintenance for all this items.</strong></p>
                  <p>&nbsp;</p>
                  <p>We will deliver  and  connect two full propane tanks to your  villa so we can assure the hot  water  system is functioning properly. We understand that you may not be here  immediately to  receive your villa, but our pool and garden staff will commence the maintenance of  your property on your behalf in order to avoid  damage to the pool surface and overgrowth in the garden. Fees for this service will appear on your monthly statement.</p>
                  <p>In a tropical climate, buildings must have regular ventilation and cleaning to prevent problems related to insects and humidity. Until you are able to receive your villa and determine which  services you want, we will have our housekeeping staff go to clean and ventilate your villa.  Fees for these services will appear on your monthly statement. If you decline these  services  Casa Linda will not be responsible for any interior or exterior deterioration  caused by lack of  maintenance, regular cleaning and ventilation.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p><strong>C</strong><strong>li</strong><strong>e</strong><strong>n</strong><strong>t</strong><strong>s name:<u>                                                                                     </u>Villa: <u>                                </u></strong></p>
                  <p>&nbsp;</p>
                  <p><strong>S</strong><strong>i</strong><strong>gn</strong><strong>a</strong><strong>t</strong><strong>u</strong><strong>r</strong><strong>e</strong><strong>: <u>                                                      </u></strong></p>
                  <p>&nbsp;</p>
                  <p><strong>D</strong><strong>a</strong><strong>t</strong><strong>e</strong><strong>: <u>                                       </u></strong></p> 
          </div>
        </div>
      </div>
    );
  };
  
export default Info7PageContent;