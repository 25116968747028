const Info3PageContent = () => {

    return(
      <div className="container">
      
        <h1>Subdivision fee</h1>
        <div className="row justify-content-center">

            <div className="col">   
              <p><img className="img-fluid"  src="img/sf1.PNG" alt="" /></p>
              <p>&nbsp;</p>
                
              <p><img className="img-fluid"  src="img/sf2.PNG" alt="" /></p>
              <p>&nbsp;</p>
                
              <p><img className="img-fluid"  src="img/sf3.PNG" alt="" /></p>
              <p>&nbsp;</p>
            </div>
        </div>
      </div>
    );
  };
  
export default Info3PageContent;