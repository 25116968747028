const Info1PageContent = () => {

    return(
      <div className="container">
        <h1>Vacation Club</h1>
        <p>&nbsp;</p>
              <h2>OUR CASA LINDA VACATION CLUB OFFERS A UNIQUE MIXTURE OF FLEXIBILITY, SERVICES, AND PRIVACY. </h2>
              <p>&nbsp;</p>
              <h3>YOU CAN HAVE IT ALL RIGHT HERE.</h3>
              <p>We&rsquo;ve set up our vacation club with you in mind – for the long term.</p>
              <ul>
                <li>Free shuttle bus to nearby towns and private beach clubs.</li>
                <li>Onsite restaurants – delivery.</li>
                <li>Onsite mini-market with delivery.</li>
                <li>Waterpark with lazy river, kids area, swim against the current machine, and swim-up bar.  Specialized areas for Vacation Club Members.</li>
                <li>Tennis, bocce ball, pickleball, shuffleboard.</li>
                <li>24-hour front desk and personalized concierge service.</li>
                <li>Members rate airport pickup/drop-off.</li>
                <li>Discounts at local restaurants.</li>
                <li>Discounts on local activities.</li>
                <li>Beach Clubs on Sosua and Cabarete Beaches.</li>
                <li>Private pools, outstanding service, endless fun, and new adventures.</li>
              </ul>
              <h3>YOUR VACATION.  YOUR WAY.</h3>
              <p>&nbsp;</p>
              <div>
                <div>
                  <h3>HOW DOES IT WORK?</h3>
                  <ul>
                    <li>Purchase 1 or more weeks of time in a 2, 3, 4 or 6 bedroom villa</li>
                    <li>Your purchase lasts 30-years and can be willed, sold or given away</li>
                    <li>You have 5 to 10 weeks to use your time in Casa Linda, or rent or exchange (subject to availability)</li>
                    <li>You only pay a service fee for your week (when you use it)</li>
                    <li>You can check in any day of the week</li>
                    <li>Reserve up to 12-months in advance to 30 days prior to arrival</li>
                    <li>Daily housekeeping and pool/garden service included</li>
                    <li>You are free to use your Casa Linda villa as a rental or possibly as a Vacation Club villa. </li>
                    <img src="img/trip-collage4.jpg" width="811" height="540" alt=""/>
                  </ul>
                </div>
              </div>
              <div>
                <div>
                  <p>If you decide to purchase your own Casa Linda villa and permanently join our community, your vacation club investment will be used to reduce your purchase price by the same amount (within 18-months).</p>
                </div>
              </div>
              <p>You have trading power</p>
              <p><img src="img/INTERVAL-300x97.png" width="300" height="97" alt=""/></p>
              <p>Your purchase includes membership in Interval International.  Exchange your Casa Linda villa for over 3,200 quality resorts around the world</p>
              <p><img src="img/VACANCY-REWARDS.png" width="225" height="153" alt=""/></p>
              <p>Your membership includes one international traveler membership with Vacancy Rewards.  Redeem for cruises, hotels, airlines, rental vehicles and vacation rentals. </p>
              <p>&nbsp;</p>
              <div>
                <div>
                  <h2>WE ARE STANDING BY</h2>
                </div>
              </div>
              <div>
                <div>
                  <h4>GUILLERMO ROSARIO</h4>
                  <p><a href="mailto:guillermo@theclubcasalinda.com">guillermo@theclubcasalinda.com</a></p>
                  <p>+1 829-645-8010</p>
                </div>
              </div>

      </div>
    );
  };
  
export default Info1PageContent;