import { useContext, useState } from 'react';
import AuthContext from '../../store/auth-context';
import PdfModal from '../PdfModal/PdfModal';
import {Link} from "react-router-dom";

const baseServer='https://lots.casalindacity.com/';



const MyvillaPageContent = () => {
    
    const authCtx = useContext(AuthContext);
    const vi = authCtx.villasDetails;
    const villas = JSON.parse(vi);
    const villaslength = villas.length;
    let sVilla=JSON.parse(authCtx.villaSelected);
    let dollarUSLocale = Intl.NumberFormat('en-US');

    const [selected, setSelected] = useState(sVilla);
    //console.log(selected);


    const HandleVillaChange = (event) =>{
        let villaChosen=JSON.parse(event.target.value);
        setSelected(villaChosen);
       // const authCtx = useContext(AuthContext);
        //console.log(event.target.value);
        localStorage.setItem('selected', event.target.value);
        window.location.reload();
    }

    let urlPdf=baseServer+selected['planinlotfile'];
    const enteredUsername='';
    const [error, setError] = useState();
    
    const addPdfHandler = (event) => {
        event.preventDefault();
        if (enteredUsername.trim().length === 0 ) {
        setError({
            title: 'In lot for villa # '+selected['no'],
            message: 'In lot and 3D villa # '+selected['no'],
            pdfFile: urlPdf,
        });
        return;
        }
        
    };

    /*=======================master plan============================================*/

    let urlPdf2=baseServer+selected['mpfile'];
    
    const addPdfHandler2 = (event) => {
        event.preventDefault();
        if (enteredUsername.trim().length === 0 ) {
        setError({
            title: 'Master Plan for villa # '+selected['no'],
            message: 'Master Plan villa # '+selected['no'],
            pdfFile: urlPdf2,
        });
        return;
        }
        
    };

    const errorHandler = () => {
      setError(null);
    };
    

    return (
        <div className="container">
            {error && (
            <PdfModal
              title={error.title}
              message={error.message}
              pdf={error.pdfFile}
              onConfirm={errorHandler}
            />
          )}
            <h2 style={{color:'purple', marginTop:'3%'}}>My page - Villa's details</h2>
            <div className="progress">
           {/* <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: '38%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">38%</div>*/}
            </div>
            <h1 style={{marginTop:'3%'}}>General villa details</h1>
            <div className="row">
                <div className="col-6">
                <div className="text-center">
                    <img src={"villa/"+selected['villatype'].trim()+".jpg"} className="img-fluid rounded" alt="..." />
                </div>
                </div>
                <div className="col">
                    <h3>Villa <span style={{color:'purple'}}> 
                    { 
                    villaslength>1 
                    ? 
                    <select value={JSON.stringify(selected)} onChange={HandleVillaChange}>
                        {villas?.map(villa => <option key={villa.id} value={JSON.stringify(villa)} >{villa.no}</option>) }
                    </select>
                    :
                    <strong>{villas?.map(villa => villa.no) }</strong> 
                    }
                    </span></h3>
                    <h1 style={{fontSize:'3em'}}>{selected['villatype']}</h1>
                    <h3>${dollarUSLocale.format(selected['salesprice'])}</h3>
                </div>
                <div className="col text-center">
                    <Link to="/construction"  onClick={addPdfHandler}>
                        <i className="fa-duotone fa-island-tropical fa-3x" style={{color:'green'}}></i>
                        <h4>Lot & Landscaping Included</h4>
                        <p>Click here to see plans</p>
                    </Link>
                        <br/>
                    <Link to="/construction"  onClick={addPdfHandler2}>
                        <i className="fa-duotone fa-map-location-dot fa-3x " style={{color:'#00B0F6'}}></i>
                        <h4>Master plan showing location of the lot</h4>
                        <p>Click here to see plan</p>
                    </Link>
                </div>
            </div>
            <div className="row">
                <div className="col text-center">
                    <i className="fa-duotone fa-bed-empty fa-3x" style={{color:'purple'}}></i>
                    <h4>{selected['qtybed']} Bedrooms</h4>
                </div>
                <div className="col text-center">
                    <i className="fa-duotone fa-shower fa-3x" style={{color:'black'}}></i>
                    <h4>{selected['qtybath']} Baths</h4>
                </div>
                <div className="col text-center">
                    <i className="fa-duotone fa-person-swimming fa-3x " style={{color:'#00B0F6'}}></i>
                    <h4>{new Intl.NumberFormat().format(selected['pool_gallons'])} Swimming Pool</h4>
                </div>
               
                <div className="col text-center">
                    <i className="fa-duotone fa-house-person-return fa-3x" style={{color:'purple'}}></i>
                    <h4>{new Intl.NumberFormat().format(selected['living_m2'])} m2 of living area</h4>
                </div>
                <div className="col text-center">
                    <i className="fa-duotone fa-mountains fa-3x" style={{color:'black'}}></i>
                    <h4>{new Intl.NumberFormat().format(selected['total_m2'])} m2 total of lot</h4>
                </div>
                
            </div>
        </div>
    );
};

export default MyvillaPageContent;