import {useState, useEffect, useContext} from 'react'
import AuthContext from '../../store/auth-context';

import axios from 'axios';

const ProgressPageContent = () => {

  const [wreports, setWreports]=useState(null)
  const [wrlength, setWrlength]=useState(null)

  const authCtx = useContext(AuthContext);
  const vi = authCtx.villaSelected;
  const villa = JSON.parse(vi);

  const list_reports = [];

  //===========CONNECT API TO CHECK WEEKLY REPORT==========================
  useEffect(() => {
    //Runs only on the first render
      const villasUrl='https://api.casalindacity.com/villa-weeklyreports/'+villa.id;
      
      const apiHeader=  {
                          headers: { 'Content-Type': 'application/json', 'Token': '3d524a53c110e4c22463b10ed32cef9d'  }
                        };
    
      const axiosFetch = axios.get

      axiosFetch(villasUrl,apiHeader).then(function(axiosFetchResult) {

        const weeklyReports=JSON.stringify(axiosFetchResult.data);
        //const weeklyReports=JSON.parse(axiosFetchResult.data);
        //console.log(JSON.parse(weeklyReports))
        setWreports(JSON.parse(weeklyReports))
        setWrlength(axiosFetchResult.data.length)
        //console.log(typeof weeklyReports)
        //console.log(typeof wreports)
        //console.log(wrlength)
        //alert(axiosFetchResult.data.length)
      })

  }, [villa.id]);

 //========================END CONNECTING TO CHECK FOR WEEKLY REPORTS=================
     

    return (
  
      <div className="container">
          <h2 style={{color:'purple', MarginTop:'50px'}}>Construction progress</h2>
          <div className="progress">
          {/*<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: '38%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">38%</div>*/}
        </div>
        <h3 style={{color:'green', marginTop:'30px'}}>Photo Journal of construction</h3>
          { /*wrlength>0 ? 'Hay reportes' : 'vacio' */}

       {/*} <div className="card" style={{Margin:'10px'}}>
          <div className="card-body">
            <h5 className="card-title">Lot Reservation</h5>
            <p className="card-text">
              This is a wider card with supporting text below as a natural lead-in to additional
              content. This content is a little bit longer.
            </p>
            <p className="card-text">
              <small className="text-muted">Last updated 3 mins ago</small>
            </p>
          </div>
          <img src="images/journal/journal1.jpg" className="card-img-bottom" alt="Camera"/>
        </div>*/}
        { (wrlength>0 ) &&(
            wreports.map((r) => {
              //console.log(`DATOS: {JSON.stringify(r.id)}`);
              //alert(`DATOS: ${JSON.stringify(r)}`);
              //list_reports.push(<li key={r.id}>`DATOS: {JSON.stringify(r)}`</li>);
              return (<div className="card" style={{Margin:'10px'}} key={r.id}>
              <div className="card-body">
                <h5 className="card-title">{r.reporttitle}</h5>
                <p className="card-text">
                  {r.reporttxt}
                </p>
                <p className="card-text">
                  <small className="text-muted">Last updated on {r.date}</small>
                </p>
              </div>
              <img src={`https://lots.casalindacity.com/${r.reportpic}`} className="card-img-bottom" alt="Camera"/>
            </div>)
            })
        )} 

       { ((!wrlength>0 )&&(wrlength!==null)) &&(
        <h1>There is not weekly reports yet for this villa</h1>
       )}

      { (wrlength===null ) &&(
              <h1>Loading...</h1>
      )}
  </div>
     
    );
};

export default ProgressPageContent;