import React, {useContext, useState } from 'react';

//import Card from '../PdfModal/Card';
//import Button from '../PdfModal/Button';
import PdfModal from '../PdfModal/PdfModal';

import {Link} from "react-router-dom";

import AuthContext from '../../store/auth-context';

const baseServer='https://lots.casalindacity.com/';

var test1 = true;



const FinancialPageContent = () => {

  const authCtx = useContext(AuthContext);
  const villaSelected=authCtx.villaSelected;
  let sVilla;
  villaSelected===undefined ? sVilla='' : sVilla=JSON.parse(villaSelected)
  let urlPdf=baseServer+sVilla['qtnfile'];

    const enteredUsername = '';
    
    const [error, setError] = useState();
    //const [filePdf, setFilePdf] = useState();

    const addPdfHandler = (event) => {
      event.preventDefault();
      if (enteredUsername.trim().length === 0 ) {
        setError({
          title: 'Quotation lot # '+sVilla['no'],
          message: 'Below is the Quotation for villa # '+sVilla['no'],
          pdfFile: urlPdf,
        });
        return;
      }
    
    };

    const errorHandler = () => {
        setError(null);
      };
    
    return (
        <div className="container">
       {error && (
        <PdfModal
          title={error.title}
          message={error.message}
          pdf={error.pdfFile}
          onConfirm={errorHandler}
        />
      )}

    {test1 === true ?
     <div>
        <h2 style={{color:'purple', marginTop:'4%'}}>My Page - Financial</h2>
        <div className="progress">
        {/*<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: '38%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">38%</div>*/}
      </div>
      <h1 style={{color:'black', marginTop:'3%'}}>Financial details</h1>
         <div className="row">
           {/* <div className="col" style={{margin:'3%', textAlign:'center'}}>
              <Link to="/mortgage">
                  <i className="fa-duotone fa-money-bill-transfer fa-4x"></i>
                  <h3>Mortgage</h3>
              </Link>	
          </div>*/}
          <div className="col" style={{margin:'3%', textAlign:'center'}} >
            {/*<!--<a href={urlPdf} target="_blank" rel="noreferrer">
                  <i className="fa-duotone fa-file-invoice-dollar fa-4x"></i>
                  
             </a>-->*/}	
            <Link to="/financial" onClick={addPdfHandler}>
                <i className="fa-duotone fa-file-invoice-dollar fa-4x"></i>
                <h3>Quotation</h3>
            </Link>

          </div>
      { /* </div>
           <div className="row">*/}
            <div className="col" style={{margin:'3%', textAlign:'center'}}>
              <Link to="/payments">
                  <i className="fa-duotone fa-money-check-dollar fa-4x"></i>
                  <h3>Payments</h3>
              </Link>
            
          </div>

        </div> </div>
            
       : <div></div>}   </div>);
    
};

export default FinancialPageContent;