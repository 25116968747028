const Info5PageContent = () => {

    return(
      <div className="container">
      
        <h1>BPD Wire Transfer Info</h1>
        <div className="row justify-content-center">

            <div className="col">  
            <p><img src="img/wt.PNG" alt="" /></p> 
   
            </div>
        </div>
      </div>
    );
  };
  
export default Info5PageContent;