import { useContext, useRef } from 'react';
import AuthContext from '../../store/auth-context';

import emailjs from '@emailjs/browser';


const Contact = () => {
    const authCtx = useContext(AuthContext);
    const owner = authCtx.dataInf;
    let propietario=JSON.parse(owner);

    const villaSelected=authCtx.villaSelected;
    let villa;
    villaSelected===undefined ? villa='' : villa=JSON.parse(villaSelected)

    const form = useRef();

    const sendEmail = (e) => {
       
        e.preventDefault();
        const YOUR_SERVICE_ID='service_4f3u4z9';
        const YOUR_TEMPLATE_ID='template_g5iwe3v';
        const YOUR_PUBLIC_KEY='VsEASoMMBr7KgfWVK';

        emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, e.target, YOUR_PUBLIC_KEY)
          .then((result) => {
              console.log('result', result.text);
          }, (error) => {
              console.log('result err', error.text);
          });
          alert('Your message has been successfully sent');
          e.target.reset()
    }
    
return(
    <div className="container">
        <h1 style={{ color: 'purple', marginTop:'3%'}}>Contact Information</h1><section className="mb-4">
            <h2 className="h1-responsive font-weight-bold text-center my-4">Contact us</h2>
            <p className="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
                a matter of hours to help you.</p>

            <div className="row">
                <div className="col-md-9 mb-md-0 mb-5">
                    <form ref={form} onSubmit={sendEmail} id="contact-form" name="contact-form">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                    <label htmlFor="name" className="">Your name</label>
                                    <input type="text" id="name" name="from_name" value={propietario['full_name']} className="form-control" readOnly/>
                                    <input type="hidden" id="name" name="villa" value={villa['no']} className="form-control" readOnly/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                <label htmlFor="email" className="">Your email</label>
                                    <input type="text" id="email" name="email" value={propietario['email']} className="form-control" readOnly />
                                    <input type="hidden" id="name" name="reply_to" value={propietario['email']} className="form-control" readOnly/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="md-form mb-0">
                                    <label htmlFor="subject" className="">Subject</label>
                                    <input type="text" id="subject" name="subject" className="form-control" required />
                                   
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">

                                <div className="md-form">
                                    <label htmlFor="message">Your message</label>
                                    <textarea type="text" id="message" name="message" rows="2" className="form-control md-textarea" required></textarea>
                                </div>

                            </div>
                        </div>
                   

                    <div className="text-center text-md-left">
                        <button className="btn btn-primary" type="submit" style={{margin:'2%'}} >Send</button>
                    </div>
                    </form>
                    <div className="status"></div>
                </div>
                <div className="col-md-3 text-center">
                    <ul className="list-unstyled mb-0">
                        <li><i className="fas fa-map-marker-alt fa-2x"></i>
                            <p>Sosua, Pto. Pta. 57000, D.R.</p>
                        </li>

                        <li><i className="fas fa-phone mt-4 fa-2x"></i>
                            <p>+ 01 809 571 1190</p>
                        </li>

                        <li><i className="fas fa-envelope mt-4 fa-2x"></i>
                            <p>kevin@casalindacity.com</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
</div>
);
};

export default Contact;