//import {Link} from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../../store/auth-context";

const PaymentsPageContent = () => {

    const authCtx=useContext(AuthContext);
    const villa=authCtx.villaSelected;
    const v=JSON.parse(villa);
    let USD = Intl.NumberFormat('en-US');
    let reservation = parseFloat(v['rreceived'],10)+parseFloat(v['escrow'],10);
    let salesprice=parseFloat(v['salesprice'],10).toFixed(2);
    let p1=parseFloat(v['cpayment'],10).toFixed(2);
    let p2=parseFloat(v['cpayment2'],10).toFixed(2);
    let p3=parseFloat(v['cpayment3'],10).toFixed(2);
    let p4=parseFloat(v['cpayment4'],10).toFixed(2);
    const re=salesprice-reservation-p1-p2-p3-p4;

    return (
      <div className="container">
            <h2 style={{color:'purple', marginTop:'3%'}}>My Villa - Financial</h2>
            <div className="progress">
            {/*<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: '38%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">38%</div>*/}
          </div>
          <h3 style={{color:'black', marginTop:'2%'}}>Payment details for villa # {v['no']}</h3>
            
          <table className="table" style={{marginBottom:'5em'}}>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Description</th>
                <th scope="col">Date</th>
                <th scope="col" className="text-end">Payment Amount</th>
                <th scope="col" className="text-end">Remaining balance</th>
              </tr>
            </thead>
            <tbody>
                <tr>
                  <th scope="row">0</th>
                  <td>Villa Sales Price</td>
                  <td ></td>
                  <td className="text-end">0.00</td>
                  <td className="text-end fw-bold">$ {USD.format(v['salesprice'])}</td>
                </tr>
                <tr>
                  <th scope="row">1</th>
                  <td>Deposit / escrow of reservation</td>
                  <td >{v['deposit_payment_date']}</td>
                  <td className="text-end">$ {USD.format(reservation)}</td>
                  <td className="text-end fw-bold">$ {USD.format(salesprice-reservation)}</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>First Construction Payment</td>
                  <td >{v['first_payment_date']}</td>
                  <td className="text-end">$ {USD.format(v['cpayment'])}</td>
                  <td className="text-end fw-bold">$ {USD.format(salesprice-reservation-p1)}</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td >Second Construction Payment</td>
                  <td >{v['second_payment_date']}</td>
                  <td className="text-end">$ {USD.format(v['cpayment2'])}</td>
                  <td className="text-end fw-bold">$ {USD.format(salesprice-reservation-p1-p2)}</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td >Third Construction Payment</td>
                  <td >{v['third_payment_date']}</td>
                  <td className="text-end">$ {USD.format(v['cpayment3'])}</td>
                  <td className="text-end fw-bold">$ {USD.format(salesprice-reservation-p1-p2-p3)}</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td >Fourth Construction Payment</td>
                  <td >{v['fourth_payment_date']}</td>
                  <td className="text-end">$ {USD.format(v['cpayment4'])}</td>
                  <td className="text-end fw-bold">$ {USD.format(re)}</td>
                </tr>
                
            </tbody>
          </table>    
    </div>
    );
};

export default PaymentsPageContent;