import {useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import AuthContext from '../../store/auth-context';
//import classes from './MainNavigation.module.css';

// /import './MainNavigation.css';




const MainNavigation = () => {
  const authCtx = useContext(AuthContext);

  const vi = authCtx.villasDetails;
  let villas;
  vi===undefined ? villas='' : villas = JSON.parse(vi)


  const isLoggedIn = authCtx.isLoggedIn;
  const qtyVillas = authCtx.qtyVillas;
  const villaSelected=authCtx.villaSelected;
  let sVilla;
//alert(villaSelected);
//console.log(JSON.parse(villaSelected));
  villaSelected===undefined ? sVilla='' : sVilla=JSON.parse(villaSelected)
  //const villa=sVilla;

 // const location = useLocation();
  //console.log(location.pathname);

 
const[villa, setVilla]=useState(sVilla);

const HandleVillaChange = (props) =>{

  //let villaChosen=props;
  setVilla(JSON.parse(props));
  localStorage.setItem('selected', props);
  console.log('villa:', villa);
  window.location.reload();
  //alert(props)
}




  const logoutHandler = () => {
    authCtx.logout();
    // optional: redirect the user
  };

  return (
      <>
   
          <header>
            <div className="px-3 py-2 bg-purple text-white">
              <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                  <Link to="/home" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
                    <img className="img-fluid" src="logo.png" alt="My Page" width={'50%'}/>
                  </Link>
                  {/*isLoggedIn && (  
                  <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                    <li>
                      <Link to="/myvilla" className="nav-link text-secondary">
                        <svg className="bi d-block mx-auto mb-1" width="24" height="24"><use xlinkHref="#home"/></svg>
                        { qtyVillas>1 ? 'My Villas' : 'My Villa' }
                      </Link>
                    </li>
                    <li>
                      <Link to="/progress" className="nav-link text-white">
                        <svg className="bi d-block mx-auto mb-1" width="24" height="24"><use xlinkHref="#speedometer2"/></svg>
                        Progress
                      </Link>
                    </li>
                    <li>
                      <Link to="/financial" className="nav-link text-white">
                        <svg className="bi d-block mx-auto mb-1" width="24" height="24"><use xlinkHref="#table"/></svg>
                        Financial
                      </Link>
                    </li>
                    <li>
                      <Link to="/legal" className="nav-link text-white">
                        <svg className="bi d-block mx-auto mb-1" width="24" height="24"><use xlinkHref="#grid"/></svg>
                        Legal
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile2" className="nav-link text-white">
                        <svg className="bi d-block mx-auto mb-1" width="24" height="24"><use xlinkHref="#people-circle"/></svg>
                        Profile
                      </Link>
                    </li>
                  </ul>
                  )*/}
                </div>
              </div>
            </div>
          
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
          {isLoggedIn && (  
            <Link className="navbar-brand" to="/">Home</Link>
          )}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
           
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {isLoggedIn && (  
                <>
                 { qtyVillas>1 ? ( 
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" aria-current="page" to="/myvilla" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">  { qtyVillas>1 && ('My Villas') }</Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {villas?.map(villa => <li key={villa.id}><button   className="dropdown-item" onClick={()=>{HandleVillaChange(JSON.stringify(villa))}}>  {villa.no} </button></li>)}
                  </ul>
                  </li>
                  )

                  :
                  
                  (<li className="nav-item">
                    <Link className="nav-link" to='/myvilla'>My Villa</Link>
                  </li>)


                  }


                <li className="nav-item">
                    <Link className="nav-link" to='/legal'>Legal</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to='/financial'>Financial</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to='/construction'>Construction</Link>
                </li>
                
                {/*<li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/legal" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Legal
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link className="dropdown-item" to="/legal">Overview</Link></li>
                    <li><Link className="dropdown-item" to="/legal">Reservation Form</Link></li>
                   
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/financial" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Financial
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">

                    <li><Link className="dropdown-item" to="/financial">Quotation</Link></li>
                    <li><Link className="dropdown-item" to="/financial">Payments</Link></li>
                  </ul>
                </li>
            <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/construction" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Construction
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link className="dropdown-item" to="/construction">Overview</Link></li>
                    <li><Link className="dropdown-item" to="/construction">In lot and 3D</Link></li>
                 
                    <li><Link className="dropdown-item" to="/construction">Construction Stage</Link></li>
                  </ul>
            </li>*/}
            <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                       Important Information
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link className="dropdown-item" to="/vacation-club">Vacation Club</Link></li>
                    <li><Link className="dropdown-item" to="/amenities">Amenities</Link></li>
                   {/* <li><Link className="dropdown-item" to="/subdivision-fee">Subdivision fee</Link></li>
                    <li><Link className="dropdown-item" to="/auth-pets">Authorization to have pets</Link></li>
                    <li><Link className="dropdown-item" to="/wire-transfer">BPD Wire Transfer Info</Link></li>
                    <li><Link className="dropdown-item" to="/tv-internet">Cable and Internet (Delancer)</Link></li>
                    <li><Link className="dropdown-item" to="/features">General features</Link></li>
                    <li><Link className="dropdown-item" to="/personal-info">Personal Information request</Link></li>
                    <li><Link className="dropdown-item" to="/roof-maintenance">Roof Maintenance</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link className="dropdown-item" to="/shuttle-bus">Shuttle bus Schedule</Link></li>*/}
                  </ul>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to='/contact'>Contact us</Link>
                </li>
                </>
                  )}
              </ul>

              {/* Cambio Kevin */}

          
              { (isLoggedIn && villa!==null && villa!=='') ?
                  qtyVillas>1 ? <span style={{marginRight:'2%', color:'purple', fontWeight:'bold'}}>Your selected villa is: {villa['no']}</span> : <span style={{marginRight:'2%', color:'purple', fontWeight:'bold'}}> Villa # {villa['no']}</span> 
                 : <span></span>
              }
              

                <div className="text-end">

                {!isLoggedIn && (
                  
                    <Link to='/auth' className="btn btn-primary">Login</Link>
                 
                )}
                {isLoggedIn && (
                  
                  <button type="button" className="btn btn-primary" onClick={logoutHandler}>Logout</button>
                )}
                  
                </div>
            </div>
          </div>
        </nav>

          
          </header>

          <div className="b-example-divider"></div>
          
    </>
  );
};

export default MainNavigation;
