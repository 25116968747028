import './HomePageApp.css';

//import classes from './HomePageApp.module.css';
import {Link} from "react-router-dom";

import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
//import { computeStyles } from '@popperjs/core';




const HomePageContent = () => {
    const authCtx = useContext(AuthContext);
    //const isLoggedIn = authCtx.isLoggedIn;
    //const token = authCtx.token;
    const owner = authCtx.dataInf;
    const vi = authCtx.villasDetails;
    let propietario;
    let villas;

    let villaslength=authCtx.qtyVillas;


    //console.log(JSON.parse(vi));
    owner===undefined ? propietario='' : propietario=JSON.parse(owner)
  
    //console.log(propietario);
    
    villas===undefined ? villas='' : villas=JSON.parse(vi)
    //console.log(villas);

    //var villas = JSON.parse(vi);

    //const villas="";

    //const villaslength = villas.length;
    //console.log(villaslength);
    //console.log(villas);
    //const propietario="propietarios";
   

    //console.log(JSON.parse(owner));
    
    //const result = Object.values(owner);
    //console.log(result);

    //console.log(token);
   // console.log(owner[0].fname);

  return (
    <div className="container">
    <div className="row justify-content-center">
      <div className="col">    
        <h1 className="text-center" style={{margin:'3%'}}>Welcome <span style={{color:'purple'}}> {propietario['full_name']} </span></h1>
        {/*villas === undefined ? villas = '' : villas.map(villa => <h2 key={Math.random()}>{villa.no}</h2>) */}
      </div>
    </div>
    
      <div className="row">
      <div className="col border text-center rounded-3" style={{margin:'3%'}}>
          <Link to="/myvilla">
              <i className="fa-duotone fa-house-building fa-5x" ></i>
              { villaslength>1 ? <h2>My Villas</h2> : <h2>My Villa</h2>}
          </Link>	
      </div>
      
        <div className="col border rounded-3 text-center" style={{margin:'3%'}}>
          <Link to="/construction">
              <i className="fa-duotone fa-person-digging fa-5x" ></i>
              <h2>Construction</h2>
          </Link>	
      </div>
      <div className="col border rounded-3 text-center" style={{margin:'3%'}}>
          <Link to="/financial">
              <i className="fa-duotone fa-money-check-dollar fa-5x"  ></i>
              <h2>Financial</h2>
          </Link>	
      </div>
      
    </div>
    <div className="row">
        <div className="col border rounded-3 text-center" style={{margin:'3%'}}>
          <Link to="/progress">
              <i className="fa-duotone fa-list-check fa-5x" ></i>
              <h2>Progress</h2>
          </Link>	
      </div>
      <div className="col border rounded-3 text-center" style={{margin:'3%'}}>
          <Link to="/legal">
              <i className="fa-duotone fa-scale-balanced fa-5x" ></i>
              <h2>Legal</h2>
          </Link>	
      </div>
      <div className="col border rounded-3 text-center" style={{margin:'3%'}}>
          <Link to="/profile2">
              <i className="fa-duotone fa-id-card fa-5x" ></i>
              <h2>Profile</h2>
          </Link>
      </div>
    </div>
  </div>
  );
};

export default HomePageContent;