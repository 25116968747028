 import {Link} from "react-router-dom";
 import {useContext, useState} from "react";
 import AuthContext from "../../store/auth-context";
 import PdfModal from '../PdfModal/PdfModal';

const baseServer='https://lots.casalindacity.com/';

const LegalPageContent = () => {
    const authCtx=useContext(AuthContext);
    const villaSelected=authCtx.villaSelected;
    let sVilla;

    villaSelected===undefined ? sVilla='' : sVilla=JSON.parse(villaSelected)

    let urlPdf=baseServer+sVilla['resfile'];
    let urlPdf2=baseServer+sVilla['profile'];
    const enteredUsername='';
    const [error, setError] = useState();
    
    const addPdfHandler = (event) => {
        event.preventDefault();
        if (enteredUsername.trim().length === 0 ) {
        setError({
            title: 'Reservation Form for villa # '+sVilla['no'],
            message: 'Reservation for Lot # '+sVilla['no'],
            pdfFile: urlPdf,
        });
        return;
        }
        
    };
    const addPdfHandler2 = (event) => {
        event.preventDefault();
        if (enteredUsername.trim().length === 0 ) {
        setError({
            title: 'Promise of Sale for Villa # '+sVilla['no'],
            message: 'Promise for Lot # '+sVilla['no'],
            pdfFile: urlPdf2,
        });
        return;
        }
        
    };

    const errorHandler = () => {
      setError(null);
    };
    

    return (
        <div className="container">
            {error && (
            <PdfModal
              title={error.title}
              message={error.message}
              pdf={error.pdfFile}
              onConfirm={errorHandler}
            />
          )}
            <h2 style={{color:'purple', marginTop:'3%'}}>My Page - Legal</h2>
            <div className="progress">
                {/*<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: '38%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">38%</div>*/}
            </div>
            <h1 style={{color:'black', marginTop:'3%'}}>Legal details</h1>
            {sVilla['lawyer_name'] && (<p>Lawyer name: {sVilla['lawyer_name']}</p>)}

            <div className="row">
                <div className="col" style={{margin:'3%', textAlign:'center'}}>
                   {sVilla['resfile'] &&( <Link to="/legal" onClick={addPdfHandler}>
                        <i className="fa-duotone fa-file-certificate fa-5x"></i>
                        <h2>Reservation form</h2>
                    </Link>	
                   )}
                </div>
                <div className="col" style={{margin:'3%', textAlign:'center'}}>
                    {sVilla['pafile'] &&(   
                     <Link to="/legal">
                        <i className="fa-duotone fa-gavel fa-5x"></i>
                        <h2>Power of attorney (if required)</h2>
                    </Link>	
                     )}
                </div>
            </div>
            <div className="row" style={{marginBottom:'10%'}}>     
                <div className="col" style={{margin:'3%', textAlign:'center'}}>
                {sVilla['profile'] &&(    <Link to="/legal" onClick={addPdfHandler2}>
                        <i className="fa-duotone fa-thumbs-up fa-5x"></i>
                        <h2>Promise of sale</h2>
                    </Link>	
                  )}
                </div>
                <div className="col" style={{margin:'3%', textAlign:'center'}}>
                {sVilla['fcfile'] &&(   
                     <Link to="/legal">
                        <i className="fa-duotone fa-file-signature fa-5x"></i>
                        <h2>Final Contract</h2>
                    </Link>
                      )}
                </div>
            </div >
        </div>
    );



};

export default LegalPageContent;